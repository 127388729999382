import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuLogo from "./MenuLogo.jsx";
import { selectedTab } from "../redux/actions/tabAction";
import { FiChevronDown } from "react-icons/fi";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FiAlignLeft } from "react-icons/fi";
import fourteen from "../../src/assets/img/banners/14-fa50a1ea.png";
import years from "../../src/assets/img/banners/LAPTOPVIEW.webp";
import yearss from "../../src/assets/img/banners/MOBILEVERSION.webp";






import "./header.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));



const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.3rem', color: "#fff" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#000',
  color: "#fff",
  border: "none",
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    border: "none",
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    border: "none",
  },
  ...theme.applyStyles('dark', {
    backgroundColor: '#000',
    border: "none",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid #000',
  backgroundColor: '#000',
  color: "#fff",
  padding: "0px 0px",
  fontFamily: "'Montserrat', sans-serif"
}));


export default function MenuCore() {

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const dispatch = useDispatch();

  

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  


  const [isActiveB1, setIsActiveB1] = useState(false);
  const [isActiveB2, setIsActiveB2] = useState(false);
  const [isActiveB3, setIsActiveB3] = useState(false);
  const [isActiveB4, setIsActiveB4] = useState(false);
  const [isActiveB5, setIsActiveB5] = useState(false);

  const selectTab = index => {
    dispatch(selectedTab(index))
  }

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [left, setLeft] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setLeft(open);
  };


  return (
    <>
      {
        !isMobileState ?
          <div className="head-container">
            <div className="head-wrapper">
              <MenuLogo />
              <div>


              {/* Make a Career in Gaming that pays you from 3 LPA to 30 LPA*


              Learn from scratch to advance level and build games like... */}

                {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ||
            window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" ||
            window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" 
            || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? <div className="Celebratingd" style={{margin: "10px auto", display: "flex", alignItems: "center", justifyContent: "right"}}><img style={{width: "12%", marginRight: "10px"}} src={fourteen} /><img style={{width: "35%"}} src={isMobileState ? yearss : years} /></div> : null} 



                <div id='AnaMenu' style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" ||
            window.location.pathname === "/landingpage/masters-and-bachelors-programs/"  || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>
                  <ul id='nav'>
                    {/* <li><Link to={"/"} onClick={() => selectTab(0)}>Home</Link></li> */}
                    <li>
                      <Link to={"/courses/"}>Courses <FiChevronDown /></Link>
                      <ul style={{ width: "390px" }}>


                      <Accordion expanded={expanded === 'panelg'} onChange={handleChange('panelg')}>
        <AccordionSummary aria-controls="panelgd-content" id="panelgd-header">
          <Typography><div className="mainc" >Bachelor’s</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="accordion-content">
                          <Link to={"/courses/bachelors-in-computer-science-and-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}> Computer Science & Game Development</Link>
                          <Link to={"/courses/bachelors-in-game-art-and-design/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>Game Art & Design</Link>
                          <Link to={"/courses/bachelors-in-augmented-reality-and-virtual-reality/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>AR & VR</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <div>
          <Typography style={{background:"#000"}} ><div className="mainc" style={{paddingLeft: "50px",background:"#000"}}><Link to={"/courses/masters-in-game-technology/"} style={{paddingLeft: "0px", paddingRight: "0px", color: "#fff",background:"#000" }}>Master’s Game Technology</Link></div></Typography>
        </div>
        
      </Accordion>
      <Accordion expanded={expanded === 'panela3'} onChange={handleChange('panela3')}>
        <AccordionSummary aria-controls="panela3d-content" id="panela3d-header">
          <Typography><div className="mainc">Advanced Diploma</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="accordion-content">
                          <Link to={"/courses/advanced-diploma-in-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}> Game Development</Link>
                          <Link to={"/courses/advanced-diploma-in-3d-game-art-digital-sculpting/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}> 3D Game Art & Digital Sculpting</Link>
                          <Link to={"/courses/advanced-diploma-in-traditional-digital-art/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>Traditional & Digital Concept Art</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography><div className="mainc">Diploma</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="accordion-content">
                          <Link to={"/courses/diploma-in-game-design-production/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>Game Design & Production</Link>
                          <Link to={"/courses/diploma-in-game-development-with-unity/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>Game Development with Unity</Link>
                          <Link to={"/courses/diploma-in-3d-environment-art-creation-for-games/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif" }}>3D Environment Art For Games</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>


                        {/* <div className="mainc" onClick={() => setIsActiveB(!isActiveB)}>Bachelor's Degree {isActiveB ? <FiChevronDown /> : <FiChevronDown />}</div>


                        {isActiveB && <div className="accordion-content">
                          <Link to={"/courses/bachelors-in-computer-science-and-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}> Computer Science & Game Development</Link>
                          <Link to={"/courses/bachelors-in-game-art-and-design/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>Game Art & Design</Link>
                          <Link to={"/courses/bachelors-in-augmented-reality-and-virtual-reality/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>AR & VR</Link>
                        </div>}


                        <div className="mainc"><Link to={"/courses/masters-in-game-technology/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>M.Sc Game Technology</Link></div>





                        <div className="mainc" onClick={() => setIsActiveA(!isActiveA)}>Advanced Diploma {isActiveA ? <FiChevronDown /> : <FiChevronDown />}</div>


                        {isActiveA && <div className="accordion-content">
                          <Link to={"/courses/advanced-diploma-in-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}> Game Development</Link>
                          <Link to={"/courses/advanced-diploma-in-3d-game-art-digital-sculpting/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}> 3D Game Art & Digital Sculpting</Link>
                          <Link to={"/courses/advanced-diploma-in-traditional-digital-art/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>Traditional & Digital Concept Art</Link>
                        </div>}


                        <div className="mainc" onClick={() => setIsActiveQ(!isActiveQ)}>Diploma {isActiveQ ? <FiChevronDown /> : <FiChevronDown />}</div>


                        {isActiveQ && <div className="accordion-content">
                          <Link to={"/courses/diploma-in-game-design-production/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>Game Design & Production</Link>
                          <Link to={"/courses/diploma-in-game-development-with-unity/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>Game Development with Unity</Link>
                          <Link to={"/courses/diploma-in-3d-environment-art-creation-for-games/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #757474" }}>3D Environment Art Games</Link>
                        </div>} */}






                      </ul>

                    </li>
                    <li>
                      <Link to={"/about-us/our-story/"}>About us <FiChevronDown /></Link>
                      <ul style={{ width: "320px" }}>




                        <div className="mainc"><Link to={"/about-us/our-story/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Our story</Link></div>

                        <div className="mainc"><Link to={"/about-us/affiliation-collaboration/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Affiliation</Link></div>

                        {/* <div className="mainc"><Link to={"/about-us/our-mentors/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Our mentors</Link></div> */}
                        <div className="mainc"><Link to={"/about-us/placement-support/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Placement Support</Link></div>


                      </ul>

                    </li>

                    <li>
                      <Link to={"/admissions/admission-process/"}>Admissions <FiChevronDown /></Link>
                      <ul style={{ width: "350px" }}>


                        <div className="mainc"><Link to={"/admissions/admission-process/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Admission Process</Link></div>

                        {/* < div className="mainc"><Link to={"/admissions/financial-assistance-and-scholarship/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff", pointerEvents: "none" }}>Financial Assistance</Link></ div> */}

                        {/* < div className="mainc"><Link to={"/admissions/financial-assistance-and-scholarship/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Financial Assistance</Link></ div> */}

                        {/* < div className="mainc"><Link to={"/scholarships/"} style={{paddingLeft: "0px", paddingRight: "0px", color: "#fff"}}>Scholarships</Link></ div> */}


                      </ul>

                    </li>

                    <li>
                      <Link to={"/life-at-bsp/alumni-success/"}>Life @ BSP <FiChevronDown /></Link>
                      <ul style={{ width: "350px" }}>


                        <div className="mainc"><Link to={"/life-at-bsp/alumni-success/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Alumni Success</Link></div>

                        < div className="mainc"><Link to={"/life-at-bsp/student-showcase/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Student Showcase</Link></ div>

                        < div className="mainc"><Link to={"/life-at-bsp/accelerator-program/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Accelerator Program</Link></ div>

                        {/* < div className="mainc"><Link to={"/life-at-bsp/events-workshops/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Events & Workshops</Link></ div> */}
                        < div className="mainc"><Link to={"/life-at-bsp/facilities/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Hostel Facilities</Link></ div>

                      </ul>

                    </li>

                    <li>
                      <Link to={"/blogs/"}>Blog</Link>
                      


                        {/* <div className="mainc"><Link to={"/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Media</Link></div> */}

                        {/* < div className="mainc">
                          <Link to={""} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}> 
                          Blog
                        </Link>
                        </ div> */}

                        {/* < div className="mainc"><Link to={"/coming-soon/"} style={{ paddingLeft: "0px", paddingRight: "0px", color: "#fff" }}>Brochures</Link></ div> */}



                    </li>




                    <li>
                      <Link to={"/contactus/"}>Contact us</Link>

                    </li>
                       
                    <li>
                      {/* <Link to={"/"} className="nobg">
                    <button className=" three button brand size200 w-full sm:w-auto" data-form-id="need-guidance" data-form="step1-button-continue" type="submit">
                    Application-form
                  </button>
                    </Link> */}
                      <Link to={"/Application-form/"}>Application-form</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="head-container">
            <div className="mobile-menu">
            {isMobileState ? <FiAlignLeft style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses" ||
            window.location.pathname === "/landingpage/masters-and-bachelors-programs/"  || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }} className="mt-menu-icon" alt="menú" onClick={toggleDrawer(true)} /> : null}
              <MenuLogo />
              <div>
              {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses" ||
            window.location.pathname === "/landingpage/masters-and-bachelors-programs/"  || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? <div className="Celebratingd" style={{margin: "10px auto", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "right"}}><img style={{width: "50%", marginBottom: "4px"}} src={fourteen} /><img style={{width: "100%"}} src={years} /></div> : null} 
              </div>
            </div>
            <SwipeableDrawer
              open={left}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              style={{ width: "90%" }}
            >
              <div className="mobile-menu-block" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" ? "none" : "block" }}>
                <div className="mobile-menu-wrapper">
                  <ul
                    className="mobile-menumt"
                  // onClick={toggleDrawer(false)}
                  // onKeyDown={toggleDrawer(false)}
                  >
                    <li>
                      <Link onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} to={"/courses/"}>Courses </Link>
                      <div onClick={() => setIsActiveB1(!isActiveB1)}>{isActiveB1 ? <FiChevronDown style={{ color: "#fff" }} /> : <FiChevronDown style={{ color: "#fff" }} />}</div>
                    </li>
                    {isActiveB1 &&
                      <>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography><div className="mainc" >Bachelor's Degree</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  onClick={toggleDrawer(false)}>
          <div className="accordion-content">
                          <Link to={"/courses/bachelors-in-computer-science-and-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}> CS & GD</Link>
                          <Link to={"/courses/bachelors-in-game-art-and-design/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}>Game Art & Design</Link>
                          <Link to={"/courses/bachelors-in-augmented-reality-and-virtual-reality/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "0px", display: "flex" }}>AR & VR</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <div>
          <Typography  onClick={toggleDrawer(false)} style={{background:"#000"}}><div className="mainc" style={{paddingLeft: "50px",background:"#000"}}><Link to={"/courses/masters-in-game-technology/"} style={{paddingLeft: "0px", paddingRight: "0px", color: "#fff", background:"#000" }}>M.Sc Game Technology</Link></div></Typography>
        </div>
        
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography><div className="mainc">Advanced Diploma</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  onClick={toggleDrawer(false)}>
          <div className="accordion-content">
                          <Link to={"/courses/advanced-diploma-in-game-development/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}> Game Development</Link>
                          <Link to={"/courses/advanced-diploma-in-3d-game-art-digital-sculpting/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}> 3D Game Art & Digital Sculpting</Link>
                          <Link to={"/courses/advanced-diploma-in-traditional-digital-art/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "0px", display: "flex" }}>Traditional & Digital Concept Art</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography><div className="mainc">Diploma</div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  onClick={toggleDrawer(false)}>
          <div className="accordion-content">
                          <Link onClick={toggleDrawer(false)} to={"/courses/diploma-in-game-design-production/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}>Game Design & Production</Link>
                          <Link  onClick={toggleDrawer(false)} to={"/courses/diploma-in-game-development-with-unity/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "10px", display: "flex" }}>Game Development with Unity</Link>
                          <Link  onClick={toggleDrawer(false)} to={"/courses/diploma-in-3d-environment-art-creation-for-games/"} style={{ fontSize: "14px", height: "35px", lineHeight: "35px", borderBottom: "1px solid #999", fontWeight: "500", fontFamily: "'Montserrat', sans-serif", color: "#fff", marginLeft: "20px", textTransform: "uppercase", marginBottom: "0px", display: "flex" }}>3D Environment Art For Games</Link>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </>}
                    <li onClick={() => setIsActiveB5(!isActiveB5)}>
                      <Link to={""} onClick={() => selectTab(0)}>About us </Link>
                      <div>{isActiveB5 ? <FiChevronDown style={{ color: "#fff" }} /> : <FiChevronDown style={{ color: "#fff" }} />}</div>
                    </li>
                    {isActiveB5 && <div className="MobileAccordian-content" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      <Link to={"/about-us/our-story/"}>Our story</Link>
                      <Link to={"/about-us/affiliation-collaboration/"}>Affiliation</Link>
                      <Link to={"/about-us/placement-support/"}>Placement Support</Link>
                    </div>}

                    <li onClick={() => setIsActiveB2(!isActiveB2)}>
                      <Link to={""} onClick={() => selectTab(0)}>Admissions </Link>
                      <div>{isActiveB2 ? <FiChevronDown style={{ color: "#fff" }} /> : <FiChevronDown style={{ color: "#fff" }} />}</div>
                    </li>
                    {isActiveB2 && <div className="MobileAccordian-content" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      <Link to={"/admissions/admission-process/"}>Admission Process</Link>
                      {/* <Link to={"/admissions/financial-assistance-and-scholarship/"} style={{pointerEvents: "none"}}>Financial Assistance</Link> */}
                    </div>}

                    <li onClick={() => setIsActiveB3(!isActiveB3)}>
                      <Link to={""}>Life @ BSP</Link>
                      <div>{isActiveB3 ? <FiChevronDown style={{ color: "#fff" }} /> : <FiChevronDown style={{ color: "#fff" }} />}</div>
                    </li>
                    {isActiveB3 && <div className="MobileAccordian-content" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      <Link to={"/life-at-bsp/alumni-success/"}>Alumni Success</Link>
                      <Link to={"/life-at-bsp/student-showcase/"}>Student Showcase</Link>
                      <Link to={"/life-at-bsp/accelerator-program/"}>Accelerator Program</Link>
                      <Link to={"/life-at-bsp/facilities/"}>Hostel Facilities</Link>
                    </div>}

                    <li onClick={() => setIsActiveB4(!isActiveB4)}>
                      <Link to={"/blogs/"}>Blog</Link>
                      <div>{isActiveB4 ? <FiChevronDown style={{ color: "#fff" }} /> : <FiChevronDown style={{ color: "#fff" }} />}</div>
                    </li>
                    {/* {isActiveB4 && <div className="MobileAccordian-content" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      <Link to={""}>Blog</Link>
                    </div>} */}
                    <li onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      <Link to={"/contactus/"}>Contact us</Link>
                    </li>

                    <li onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                      
                      <Link to={"/Application-form/"}>Application-form</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="mobile-menu-footer"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <div className="menu-divider"></div>
                  <MenuLogo />

                </div>
              </div>
            </SwipeableDrawer>
          </div>
      }
    </>
  );
}
